import { useState } from "react"
import { useSignIn } from "@clerk/remix"
import { isClerkAPIResponseError } from "@clerk/remix/errors"
import type { ClerkAPIError } from "@clerk/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { Link, useNavigate } from "@remix-run/react"
import { EyeIcon, EyeOffIcon } from "lucide-react"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Label,
} from "@/components/ui/form"
import { Input, InputSlot } from "@/components/ui/input"
import { LoginWrapper } from "@/components/shared/login"

import { LoginFormLogos } from "../logo"

export default function SignInForm() {
	const { isLoaded, signIn, setActive } = useSignIn()
	const [showPassword, setShowPassword] = useState(false)
	const [errors, setErrors] = useState<ClerkAPIError[]>()
	const navigate = useNavigate()

	const signInSchema = z.object({
		email: z.string().email({ message: "Please enter a valid email" }),
		password: z.string(),
	})

	const form = useForm({
		resolver: zodResolver(signInSchema),
		reValidateMode: "onSubmit",
		defaultValues: {
			email: "",
			password: "",
		},
	})

	const onSubmit = async () => {
		// Clear any errors that may have occurred during previous form submission
		setErrors(undefined)

		if (!isLoaded) {
			return
		}

		try {
			const signInAttempt = await signIn.create({
				identifier: form.getValues("email"),
				password: form.getValues("password"),
			})

			// If sign-in process is complete, set the created session as active
			// and redirect the user
			if (signInAttempt.status === "complete") {
				await setActive({ session: signInAttempt.createdSessionId })
				navigate("/")
			} else {
				// If the status is not complete, check why. User may need to
				// complete further steps.
				console.error(JSON.stringify(signInAttempt, null, 2))
			}
		} catch (err) {
			if (isClerkAPIResponseError(err)) setErrors(err.errors)
			console.error(JSON.stringify(err, null, 2))
		}
	}

	// Display a form to capture the user's email and password
	return (
		<LoginWrapper>
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(onSubmit)}
					className="flex w-full max-w-[300px] flex-col gap-y-2"
				>
					<LoginFormLogos />

					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Email</FormLabel>
								<FormControl>
									<Input {...field} placeholder={"Email"} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Password</FormLabel>
								<FormControl>
									<Input
										{...field}
										placeholder={"Password"}
										type={
											showPassword ? "text" : "password"
										}
									>
										<InputSlot side="end">
											<Button
												onClick={() =>
													setShowPassword(
														(prev) => !prev,
													)
												}
												type="button"
											>
												{showPassword ?
													<EyeOffIcon />
												:	<EyeIcon />}
											</Button>
										</InputSlot>
									</Input>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					{errors && (
						<FormLabel className="text-error">
							Username or password is incorrect
						</FormLabel>
					)}
					<Button
						type="submit"
						variant="primary"
						className="mt-10 w-full"
						size="lg"
					>
						Sign in
					</Button>
					<div className="mt-2 flex justify-around">
						<Button
							variant="link"
							onClick={(e) => e.preventDefault()}
						>
							<Link to="/sign-up">Create an account</Link>
						</Button>
						<Button
							variant="link"
							onClick={(e) => e.preventDefault()}
						>
							{/* TODO: Setup forgot password */}
							<Link to="">Forgot Password</Link>
						</Button>
					</div>
				</form>
			</Form>
		</LoginWrapper>
	)
}
